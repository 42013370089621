import polyglotI18nProvider from 'ra-i18n-polyglot';
import spanishMessages from '@blackbox-vision/ra-language-spanish';

export const resources = {
  institutions: {
    name: 'ONGs',
    fields: {
      name: 'Nombre',
      creationDate: 'Fecha de creación',
      summary: 'Resumen',
      banner: 'Baner',
      salesforceId: 'ID de Salesforce',
      category: 'Categoría',
      facebook: 'Link de Facebook',
      instagram: 'Link de Instagram',
      google: 'Link de Google',
      twitter: 'Link de Twiiter',
      youtube: 'Link de YouTube',
      websiteUrl: 'Link de sitio web',
      linkedIn: 'Link de LinkedIn',
      deleted: 'Inactivo',
      ngoHeader: 'NGO Header Title',
      ngoVideo: 'NGO Header Video',
      ngoImages: 'NGO Header Images',
      programs: 'Programs',
      programTitle: 'Program Title',
      programImages: 'Program Images',
      programDescription: 'Program Description'
    }
  },
  projects: {
    name: 'Proyectos',
    fields: {
      institutionId: 'ONG',
      creationDate: 'Fecha de creación',
      title: 'Título',
      objective: 'Objetivo',
      funded: 'Colectado',
      mainImage: 'Imagen',
      summary: 'Resumen'
    }
  },
  donations: {
    name: 'Donaciones Recurrentes',
    fields: {
      amount: 'Monto',
      type: 'Frecuencia',
      method: 'Medio',
      status: 'Estado',
      retries: 'Reintentos de cobro',
      errorDetails: 'Detalles de último error de cobro',
      nextRetry: 'Fecha de próximo cobro',
      creationDate: 'Fecha de creación',
      billingDate: 'Fecha de alta (de suscripción)',
      lastRetry: 'Fecha del último cobro fallido'
    }
  },
  payments: {
    name: 'Historial de Donaciones',
    fields: {
      amount: 'Monto',
      institutionId: 'ONG destino',
      status: 'Estado',
      userId: 'Donante',
      refunded: 'Reembolsado',
      referenceId: 'ID sistema externo',
      donationId: 'ID de donación asociada',
      type: 'Tipo',
      creationDate: 'Fecha de creación'
    }
  },
  users: {
    name: 'Usuarios',
    fields: {
      fullName: 'Nombre completo',
      email: 'Email',
      country: 'País',
      permission: 'Permisología',
      creationDate: 'Fecha de creación',
      institutionId: 'ONG a la que pertenece (sólo para usuarios Miembros)'
    }
  },
  home: {
    name: "Página Home",
    fields: {
      title: "Título",
      subtitle: "Subtítutlo",
      buttonText: "Texto del botón",
      buttonLink: "Enlace al hacer click en botón",
      bannerImgDesktop: "Imagen baner Desktop (ratio 16:9)",
      bannerImgMobile: "Imagen baner Mobile (ratio 9:16)"
    }
  },
  otherPages: {
    name: 'Otras Páginas'
  },
  'dynamic-pages': {
    name: 'Páginas dinámicas',
    fields: {
      title: 'Título',
      subtitle: 'Subtítulo',
      code: 'Código (único)',
      content: 'Contenido'
    }
  },
  contact: {
    name: 'Contacto'
  },
  us: {
    name: 'Nosotros'
  },
  't&c': {
    name: 'Políticas'
  },
  faq: {
    name: 'FAQ'
  }
};

const custom = {
  loadingImage: 'Cargando imagen...'
};

Object.assign(spanishMessages, { resources }, { custom });

const i18nProvider = polyglotI18nProvider(_locale =>
    spanishMessages,
  'es' // Default locale
);


export default i18nProvider;
