/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file.rawFile);

  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

export const uploadImageToCloudinary = async (file) => {
  const base64Pic = await convertFileToBase64(file);
  return fetch('https://api.cloudinary.com/v1_1/djxowy3ee/image/upload', {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ file: base64Pic, upload_preset: 'yy9huixc' })
  }).then(r => r.json())
    .then(json => json.secure_url)
};

const uploadToCloudinary = async (img, rawFile) => {
  if (img && rawFile) {
    return uploadImageToCloudinary(img);
  }
};

const uploadVideoToCloudinary = async (video) => {
  if (video) {
    const base64Pic = await convertFileToBase64(video);
    return fetch('https://api.cloudinary.com/v1_1/djxowy3ee/video/upload', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ file: base64Pic, upload_preset: 'yy9huixc', resource_type: 'auto' })
    }).then(r => r.json())
        .then(json => json.secure_url)
  }
};

const uploadForResource = async (type, resource, params, resourceName, fields = []) => {
  if (['UPDATE', 'CREATE'].includes(type) && resource === resourceName) {
    // Upload new banner to cloudinary and get response url. Null if no new banner was provided
    let data = {...params.data};
    for (let i = 0; i < fields.length; i++) {
        if (fields[i] === "ngoImages") {
          for (let j = 0; j < data["ngoImages"].length; j++) {
            const cloudinaryUrl = await uploadToCloudinary(data["ngoImages"][j], data["ngoImages"][j].rawFile);
            if (cloudinaryUrl) {
              data["ngoImages"][j].src = cloudinaryUrl;
            }
          }
        } else if (fields[i] === "programImages") {
          for (let k = 0; k < data["programs"].length; k++) {
            for (let j = 0; j < data["programs"][k]["programImages"].length; j++) {
              const cloudinaryUrl = await uploadToCloudinary(data["programs"][k]["programImages"][j], data["programs"][k]["programImages"][j].rawFile);
              if (cloudinaryUrl) {
                data["programs"][k]["programImages"][j].src = cloudinaryUrl;
              }
            }
          }
        } else if (fields[i] === "ngoVideo") {
          const cloudinaryUrl = await uploadVideoToCloudinary(params.data[fields[i]]);
          if (cloudinaryUrl) {
            // If uploaded (because raw file existed) add it to data to be send
            data[fields[i]] = cloudinaryUrl;
          }
        } else if (params.data[fields[i]]) {
          const cloudinaryUrl = await uploadToCloudinary(params.data[fields[i]], params.data[fields[i]].rawFile);
          if (cloudinaryUrl) {
            // If uploaded (because raw file existed) add it to data to be send
            data[fields[i]] = cloudinaryUrl;
          }
        }
    }
    return data;
  }
};

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadFeature = requestHandler => async (type, resource, params) => {
  let data = await uploadForResource(type, resource, params, 'projects', ['mainImage']) ||
             await uploadForResource(type, resource, params, 'institutions', ['banner', 'logo', 'ngoVideo', 'ngoImages', 'programImages']) ||
             await uploadForResource(type, resource, params, 'home', ['bannerImgDesktop', 'bannerImgMobile']);
  if (data) {
    // Execute HTTP request
    return requestHandler(type, resource, {
      ...params,
      data
    });
  }
  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params);
};

export default addUploadFeature;
