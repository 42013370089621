import config                                              from './config';
import jwtDecode                                           from 'jwt-decode';

const { apiUrl } = config;

export default {
  login: ({ email, password }) => {
    const request = new Request(`${apiUrl}/users/login`, {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error('Correo o contraseña incorrectos');
        }
        return response.json();
      })
      .then(({ token }) => {
        const { data } = jwtDecode(token);
        localStorage.setItem('token', token);
        localStorage.setItem('permission', data.permission);
      })
  },
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('permission');
    return Promise.resolve();
  },
  checkError: error => {
    // ...
  },
  checkAuth: () => {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  },
  getPermissions: () => {
    const role = localStorage.getItem('permission');
    return role ? Promise.resolve(role) : Promise.reject();
  }
};
