import React     from 'react';
import {
  List,
  Datagrid,
  TextField,
  Responsive,
  SimpleList,
  EditButton,
  TextInput,
  Edit,
  SimpleForm,
  ImageInput,
  ImageField,
  Create,
  SimpleShowLayout,
  Show
}                from 'react-admin';
import LogoField from '../components/LogoField';

export const HomeList = props => (
  <List {...props}>
    <Responsive
      small={
        <SimpleList
          primaryText={record => record.title}
          secondaryText={record => record.subtitle}
        />
      }
      medium={
        <Datagrid rowClick="show">
          <TextField source="title" />
          <TextField source="subtitle" />
          <LogoField source="bannerImgDesktop" />
          <LogoField source="bannerImgMobile" />
          <EditButton />
        </Datagrid>
      }
    />
  </List>
);

const HomeTitle = () => {
  return <span>Datos de la Homepage</span>;
};

export const HomeEdit = props => (
  <Edit title={<HomeTitle />} {...props} undoable={false}>
    <SimpleForm redirect="list">
      <TextInput required={true} source="title" />
      <TextInput required={true} source="subtitle" />
      <ImageInput required={true} source="bannerImgDesktop" accept="image/*">
        <ImageField source="src" title="bannerImgDesktop" />
      </ImageInput>
      <ImageInput required={true} source="bannerImgMobile" accept="image/*">
        <ImageField source="src" title="bannerImgMobile" />
      </ImageInput>
      <TextInput required={true} source="buttonText" />
      <TextInput required={true} source="buttonLink" />
    </SimpleForm>
  </Edit>
);

export const HomeCreate = props => (
  <Create title={<HomeTitle />} {...props} undoable={false}>
    <SimpleForm redirect="show">
      <TextInput required={true} source="title" />
      <TextInput required={true} source="subtitle" />
      <ImageInput required={true} source="bannerImgDesktop" accept="image/*">
        <ImageField source="src" title="bannerImgDesktop" />
      </ImageInput>
      <ImageInput required={true} source="bannerImgMobile" accept="image/*">
        <ImageField source="src" title="bannerImgMobile" />
      </ImageInput>
      <TextInput required={true} source="buttonText" />
      <TextInput required={true} source="buttonLink" />
    </SimpleForm>
  </Create>
);

export const HomeShow = props => (
  <Show title={<HomeTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="title" />
      <TextField source="subtitle" />
      <ImageField source="bannerImgDesktop" title="bannerImgDesktop" />
      <ImageField source="bannerImgMobile" title="bannerImgMobile" />
      <TextField source="buttonText" />
      <TextField source="buttonLink" />
    </SimpleShowLayout>
  </Show>
);