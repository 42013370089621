import React, { createElement, useState, useEffect }  from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources, usePermissions } from 'react-admin';
import { withRouter } from 'react-router-dom';
import { resources } from './i18nProvider';
import SubMenu        from './layout/SubMenu';
import ListAltIcon  from '@material-ui/icons/ListAlt';
import { useTranslate } from 'react-admin';
import GavelIcon from '@material-ui/icons/Gavel';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { get } from './api/httpClient';
import Permissions from './utils/permissions'

const i18n = resources;

/**
 * Transform a list of dynamic pages to a map of the form {@code page.code => page.id}
 *
 * @param pages Pages to map.
 * @return {[Object]} Mapped pages. String-string map.
 */
const mapToCodes = pages => (
  pages.reduce((acc, p) => {
    acc[p.code] = p.id;
    return acc;
  }, {})
);

const pagesArr = [
  // { code: 'contact', icon:  <ContactMailIcon /> },
  { code: 't&c', icon:  <GavelIcon /> },
  { code: 'us', icon:  <SupervisedUserCircleIcon /> },
  { code: 'faq', icon:  <QuestionAnswerIcon /> },
];

const Menu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [pages, setPages] = useState(null);
  const translate = useTranslate(); // returns the i18nProvider.translate() method
  const { permissions } = usePermissions();

  /**
   * Get dynamic pages so that we can map 'pagesArr' codes with corresponding DB
   * id and create page edition link.
   * Only for admins.
   */
  useEffect(() => {
    if (!Permissions.isAdmin(permissions)) {
      console.debug('Non-admin user, skipping dynamic pages request')
      return
    }

    get('/dynamic-pages')
      .then(r => r.json())
      .catch(e => { console.warn('Error getting dynamic pages, using empty array', e); return [] })
      .then(mapToCodes)
      .then(setPages)
  }, [permissions]);

  return (
    <div>
      {resources.map(resource => (
        resource.hasList && (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={resource.options && resource.options.label || i18n[resource.name].name}
            leftIcon={createElement(resource.icon)}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        )
      ))}
      { Permissions.isAdmin(permissions) &&
        <SubMenu
          handleToggle={() => setSubMenuOpen(!subMenuOpen)}
          isOpen={subMenuOpen}
          sidebarIsOpen={open}
          name="resources.otherPages.name"
          icon={<ListAltIcon/>}
          dense={open}
        >
          {pages && pagesArr.map(p => (
            <MenuItemLink
              key={p.code}
              to={(`/dynamic-pages/${pages[p.code] || p.code}`)}
              primaryText={translate(`resources.${p.code}.name`)}
              leftIcon={p.icon}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={open}
            />
          ))}
        </SubMenu>
      }
      {isXSmall && logout}
    </div>
  );
};

export default withRouter(Menu);
