import React from 'react';
import {
  Labeled,
  FunctionField
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import config         from '../config';

export const creditCardImage = (brand) => {
  brand = brand.toLowerCase().replace(' ', '');
  switch (brand) {
    case 'visa':
      return '/static/img/cards/visa.png';
    case 'master':
    case 'mastercard':
      return '/static/img/cards/master.png';
    case 'jcb':
      return '/static/img/cards/jcb.png';
    case 'discover':
      return '/static/img/cards/discover.png';
    case 'diners':
    case 'dinersclub':
      return '/static/img/cards/diners-club.png';
    case 'amex':
    case 'americanexpress':
      return '/static/img/cards/amex.png';
    case 'unionpay':
      return '/static/img/cards/union-pay.png';
    default:
      return '/static/img/cards/default.png';
  }
};

const styles = {
  card: {
    display: 'flex',
    alignItems: 'center'
  },
  cardImg: {
    maxHeight: 30
  },
  cardText: {
    marginLeft: 8
  }
};

const CardField = ({ record = {}, source, classes }) => {
  return (
    (record && record[source] ? (
      <Labeled label="Tarjeta">
        <FunctionField record={record} label="Tarjeta" render={record => (
          <div className={classes.card}>
            <img className={classes.cardImg} src={`${config.appUrl}${creditCardImage(record.card.brand)}`} />
            <span className={classes.cardText}>
              {record.card.brand} **** **** **** {record.card.last4}
            </span>
            <span className={classes.cardText}>
              Fecha expiración: {record.card.expMonth}/{record.card.expYear}
            </span>
          </div>
        )} />
      </Labeled>
    ) : null)
  )
};

export default withStyles(styles)(CardField);