import React from 'react';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  chip: {
    marginRight: 8,
  }
};

export const categoryChoices = [
  { id: 'education', name: 'Educación' },
  { id: 'economy', name: 'Economía Social' },
  { id: 'human_rights', name: 'Derechos Humanos' },
  { id: 'environment', name: 'Medio Ambiente' },
  { id: 'health_&_nutrition', name: 'Salud y Nutrición' },
  { id: 'art_&_culture', name: 'Arte y Cultura' }
];

const choicesObj = categoryChoices.reduce((prev, current) => {
  prev[current.id] = current.name;
  return prev;
}, {});

const CategoryField = ({ record = {}, source, classes }) => (
  (record[source] || []).map(category => (
    <Chip
      label={choicesObj[category]}
      className={classes.chip}
    />
  ))
);

export default withStyles(styles)(CategoryField);