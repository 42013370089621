const env = process.env.NODE_ENV || 'development';

const config = {
  development: {
    apiUrl: 'http://localhost:8080/api',
    appUrl: 'https://v2.helpargentina.org',
    baseUrl: 'http://localhost:3006'
  },
  production: {
    apiUrl: 'https://core.helpargentina.org/api',
    appUrl: 'https://v2.helpargentina.org',
    baseUrl: 'https://admin.helpargentina.org'
  }
};

export default config[env];