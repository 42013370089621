import React                from 'react';
import { Admin, Resource }  from 'react-admin';
import simpleRestProvider   from './ra-data-simple-rest';
import {
  InstitutionList,
  InstitutionEdit,
  InstitutionShow,
  InstitutionCreate
}                           from './pages/institution';
// import {
//   ProjectList,
//   ProjectEdit,
//   ProjectCreate,
//   ProjectShow
// }                           from './pages/project';
import {
  DonationCreate,
  DonationList,
  DonationEdit,
  DonationShow
}                           from './pages/donation';
import {
  UserCreate,
  UserList,
  UserEdit,
  UserShow
}                           from './pages/user';
import {
  HomeList,
  HomeShow,
  HomeEdit,
  // HomeCreate
}                           from './pages/home';
import {
  PaymentCreate,
  PaymentEdit,
  PaymentList,
  PaymentShow
}                           from './pages/payment';
import {
  PageCreate,
  PageEdit,
  PageShow
}                           from './pages/dynamic-page';

import LoginPage            from './components/LoginPage';
import Dashboard            from './pages/dashboard';
import UserIcon             from '@material-ui/icons/Group';
import BusinessIcon         from '@material-ui/icons/Business';
import LanguageIcon         from '@material-ui/icons/Language';
import RecurringIcon        from '@material-ui/icons/Replay';
import PaymentIcon          from '@material-ui/icons/Payment';
import authProvider         from './authProvider';
import addUploadFeature from './addUploadFeature';
import i18nProvider     from './i18nProvider';
import config from './config';
import Menu   from './Menu';
import Permissions from './utils/permissions'
import './App.css';

const dataProvider = simpleRestProvider(config.apiUrl);
const uploadCapableDataProvider = addUploadFeature(dataProvider);

const App = () => (
  <Admin
    locale="es"
    menu={Menu}
    i18nProvider={i18nProvider}
    dashboard={Dashboard}
    loginPage={LoginPage}
    authProvider={authProvider}
    dataProvider={uploadCapableDataProvider}
  >
    {/* Restrict access to resources by permission, see https://marmelab.com/react-admin/doc/3.0/Authorization.html */}
    { permissions => [
      Permissions.isAdmin(permissions) &&
        <Resource
          name="institutions"
          list={InstitutionList}
          edit={InstitutionEdit}
          create={InstitutionCreate}
          show={InstitutionShow}
          icon={BusinessIcon}
        />,
      // <Resource
      //   name="projects"
      //   list={ProjectList}
      //   edit={ProjectEdit}
      //   create={ProjectCreate}
      //   show={ProjectShow}
      //   icon={SpaIcon}
      // />,
      // Permissions.isAdmin(permissions) &&
      //   <Resource
      //     name="donations"
      //     list={DonationList}
      //     edit={DonationEdit}
      //     create={DonationCreate}
      //     show={DonationShow}
      //     icon={RecurringIcon}
      //   />,
      // <Resource
      //   name="payments"
      //   list={PaymentList}
      //   edit={Permissions.isAdmin(permissions) && PaymentEdit}
      //   create={Permissions.isAdmin(permissions) && PaymentCreate}
      //   show={PaymentShow}
      //   icon={PaymentIcon}
      // />,
      Permissions.isAdmin(permissions) &&
        <Resource
          name="users"
          list={UserList}
          edit={UserEdit}
          create={UserCreate}
          show={UserShow}
          icon={UserIcon}
        />,
      Permissions.isAdmin(permissions) &&
        <Resource
          name="home"
          list={HomeList}
          edit={HomeEdit}
          // create={HomeCreate}
          show={HomeShow}
          icon={LanguageIcon}
        />,
      Permissions.isAdmin(permissions) &&
        <Resource
          icon={UserIcon}
          name="dynamic-pages"
          edit={PageEdit}
          create={PageCreate}
          show={PageShow}
        />,
    ]}
  </Admin>
);

export default App;
