export default {
  /**
   * Admin user. Can control all institutions and HelpArgentina website. Reserved for HelpArgentina staff.
   */
  ADMIN: 'admin',
  /**
   * Institution member. Can view/control data and donations related to their institution only.
   */
  MEMBER: 'member',
  /**
   * Donor. No access to the admin console.
   */
  DONOR: 'donor',
}
