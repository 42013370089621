import React from 'react';
import {
  ReferenceField,
  TextField,
  Labeled
} from 'react-admin';

const ConditionalOngReference = ({ record, source, ...rest }) => (
  record && record[source] ? (
    <>
      <Labeled label="ONG">
        <ReferenceField
          source="institutionId"
          record={record} reference="institutions"
          basePath="/institutions"
        >
          <TextField source="name" {...rest} />
        </ReferenceField>
      </Labeled>
    </>
  ) : null
);

export default ConditionalOngReference;