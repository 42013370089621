import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  logo: {
    width: 50,
  }
};

const LogoField = ({ record = {}, source, classes }) =>
  <img alt={record.title} src={record[source]} className={classes.logo} />;

export default withStyles(styles)(LogoField);