import React, { useState } from 'react';
import { useLogin } from 'react-admin';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import config      from '../config';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://helpargentina.org">
        HelpArgentina
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  forgotPassword: {
    textAlign: 'center'
  },
  progress: {
    marginRight: 8,
  },
  close: {
    padding: theme.spacing(0.5),
  },
  forgotLink: {
    cursor: 'pointer'
  }
}));

export default function SignIn() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [snackOpened, setSnackOpened] = useState(null);
  const [openForgotModal, setOpenForgotModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const login = useLogin();

  const updateField = (e) => {
    const { name, value } = e.target;
    if (name === 'email') {
      setEmail(value);
    } else {
      setPassword(value);
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await login({ email, password });
    } catch (e) {
      setSnackOpened('Correo o contraseña incorrectos');
    } finally {
      setLoading(false);
    }
  };

  const closeSnack = () => {
    setSnackOpened(null);
  };

  const closeForgotModal = () => {
    setOpenForgotModal(false);
  };

  const sendForgotPasswordEmail = async (ev) => {
    ev.preventDefault();
    setLoadingModal(true);
    try {
      const response = await fetch(`${config.apiUrl}/users/reset_password`, {
        method: 'POST',
        body: JSON.stringify({ email, returnUrl: config.baseUrl }),
        headers:{
          'Content-Type': 'application/json'
        }
      });
      if (response.status !== 200) {
        setSnackOpened('Ha ocurrido un error. Por favor intente más tarde');
      } else {
        setSnackOpened('Correo enviado. Por favor revise su bandeja de entrada o spam');
      }
    } catch (e) {
      setSnackOpened('Ha ocurrido un error. Por favor intente más tarde');
    } finally {
      setLoadingModal(false);
      closeForgotModal();
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <form className={classes.form} noValidate onSubmit={submit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Correo electrónico"
            name="email"
            autoComplete="email"
            onChange={updateField}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            onChange={updateField}
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            disabled={loading}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {loading && <CircularProgress size="1em" className={classes.progress} />}
            Iniciar sesión
          </Button>
          <Grid container>
            <Grid item xs className={classes.forgotPassword}>
              <Link className={classes.forgotLink} onClick={() => setOpenForgotModal(true)} variant="body2">
                ¿Olvidaste la contraseña?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>

      {/*DIALOGS AND SNACKBAR*/}
      <Dialog open={openForgotModal} onClose={closeForgotModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">¿Olvidaste tu contraseña?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Te enviaremos un correo con instrucciones para reiniciar tu contraseña.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            defaultValue={email}
            onChange={updateField}
            id="forgot-email"
            label="Correo electrónico"
            name="email"
            type="email"
            required
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          {loadingModal ? <CircularProgress size="2em" className={classes.progress} /> : (
            <>
              <Button onClick={closeForgotModal} color="primary">
                Cancelar
              </Button>
              <Button disabled={!email} onClick={sendForgotPasswordEmail} color="primary">
                Enviar
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={!!snackOpened}
        autoHideDuration={6000}
        onClose={closeSnack}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{snackOpened}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={closeSnack}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Container>
  );
}
