import config from '../config';

const headers = new Headers({ Accept: 'application/json' });
const getReqHeaders = () => {
  const token = localStorage.getItem('token');
  headers.set('Authorization', token);
  return  headers;
};

export const get = (path) => fetch(`${config.apiUrl}${path}`, {
  method: 'GET',
  headers: getReqHeaders()
});

export const post = (path, body) => fetch(`${config.apiUrl}${path}`, {
  method: 'POST',
  headers: getReqHeaders(),
  body: JSON.stringify(body)
});
