import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

export default () => (
  <Card>
    <CardHeader title="Bienvenido a la administración" />
    <CardContent>Aquí podrá ver el listado de todas las ONG, donaciones, usuarios, etc. y gestionarlos de forma sencilla</CardContent>
  </Card>
);