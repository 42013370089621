import React                   from 'react';
import {
  List,
  Datagrid,
  TextField,
  Responsive,
  SimpleList,
  EditButton,
  Filter,
  TextInput,
  ReferenceInput,
  SelectInput,
  Edit,
  SimpleForm,
  Create,
  SimpleShowLayout,
  Show,
  AutocompleteInput,
  SelectField,
  FormDataConsumer,
  DateInput
}                              from 'react-admin';
import countries               from '../utils/countries';
import ConditionalOngReference from '../components/ConditionalOngReference';
import CardField            from '../components/CardField';
import OngAutoCompleteInput from '../components/OngAutoCompleteInput';

const validateUser = (values) => {
  const errors = {};
  if (!values.fullName) {
    errors.fullName = ['El nombre completo es obligatorio'];
  }
  if (!values.email) {
    errors.email = ['El email es obligatorio'];
  }
  if (!values.password) {
    errors.password = ['La contraseña es obligatoria'];
  }
  if (values.password && values.password.length < 6) {
    errors.password = ['La contraseña debe tener al menos 6 caracteres'];
  }
  if (values.permission === 'member' && !values.institutionId) {
    errors.institutionId = ['Si el usuario es Miembro, por favor especificar ONG a la que pertenece'];
  }
  if (values.permission !== 'member' && values.institutionId) {
    errors.institutionId = ['El usuario puede pertenecer a una ONG sólo si es de tipo Miembro. Por favor borrar'];
  }
  return errors
};

const UserFilters = (props) => (
  <Filter {...props}>
    <TextInput label="Email" source="email" alwaysOn />
    <TextInput label="Nombre" source="fullName" />
    <SelectInput label="Permisología" source="permission" choices={userPermissionChoices} allowEmpty />
    <ReferenceInput label="ONG" source="institutionId" reference="institutions" allowEmpty>
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const UserList = props => (
  <List filters={<UserFilters />} {...props}>
    <Responsive
      small={
        <SimpleList
          primaryText={record => record.fullName}
          secondaryText={record => record.email}
          tertiaryText={record => new Date(record.creationDate).toLocaleDateString()}
        />
      }
      medium={
        <Datagrid rowClick="show">
          <TextField source="id" />
          <TextField source="fullName" />
          <TextField source="email" />
          <EditButton />
        </Datagrid>
      }
    />
  </List>
);

const UserTitle = ({ record }) => {
  return <span>{record.fullName} ({record.email})</span>;
};

export const userPermissionChoices = [
  { id: 'donor', name: 'Donador' },
  { id: 'member', name: 'Miembro (ONG)' },
  { id: 'admin', name: 'Administrador' },
];

const countryChoices = countries.map(c => ({id: c, name: c}));

export const UserEdit = props => (
  <Edit title={<UserTitle />} {...props} undoable={false}>
    <SimpleForm validate={validateUser}>
      <TextInput disabled source="id" />
      <TextInput required source="fullName" />
      <TextInput required source="email" />
      <SelectInput choices={userPermissionChoices} source="permission" />
      <FormDataConsumer>
        {({ formData }) => (formData.permission === 'member' || formData.institutionId) && (
          <OngAutoCompleteInput source="institutionId"  />
        )}
      </FormDataConsumer>
      <AutocompleteInput choices={countryChoices} source="country" />
      <DateInput disabled source="creationDate" />
    </SimpleForm>
  </Edit>
);

export const UserCreate = props => (
  <Create title="Nuevo usuario" {...props} undoable={false}>
    <SimpleForm validate={validateUser} redirect="show">
      <TextInput required source="fullName" />
      <TextInput required source="email" />
      <TextInput required source="password" />
      <SelectInput choices={userPermissionChoices} source="permission" />
      <FormDataConsumer>
        {({ formData }) => (formData.permission === 'member' || formData.institutionId) && (
          <OngAutoCompleteInput source="institutionId"  />
        )}
      </FormDataConsumer>
      <AutocompleteInput choices={countryChoices} source="country" />
    </SimpleForm>
  </Create>
);

export const UserShow = props => (
  <Show title={<UserTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="fullName" />
      <TextField source="email" />
      <SelectField source="permission" choices={userPermissionChoices} />
      <ConditionalOngReference source="institutionId" />
      <TextField source="country" />
      <CardField source="card" />
    </SimpleShowLayout>
  </Show>
);