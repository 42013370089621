import React                     from 'react';
import {
  TextField,
  SelectField,
  NumberField,
  DateField,
  Labeled
}                                from 'react-admin';
import { donationStatusChoices } from '../pages/donation';
import Divider                   from '@material-ui/core/Divider';
import { resources }              from '../i18nProvider';
const { fields } = resources.donations;

const ConditionalRecurrentDonationFields = ({ record, source, ...rest }) => (
  record && record[source] === 'monthly' ? (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <p>DATOS DE DONACIONES RECURRENTES</p>
      <Divider />
      <Labeled label={fields.status} {...rest}>
        <SelectField source="status" choices={donationStatusChoices} record={record} {...rest} />
      </Labeled>
      <Labeled label={fields.retries} {...rest}>
        <NumberField source="retries" record={record} {...rest} />
      </Labeled>
      <Labeled label={fields.errorDetails} {...rest}>
        <TextField source="errorDetails" record={record} {...rest} />
      </Labeled>
      <Labeled label={fields.nextRetry}>
        <DateField source="nextRetry" record={record} {...rest} />
      </Labeled>
      <Labeled label={fields.lastRetry}>
        <DateField source="lastRetry" record={record} {...rest} />
      </Labeled>
      <Labeled label={fields.billingDate}>
        <DateField source="billingDate" record={record} {...rest} />
      </Labeled>
      <Labeled label={fields.creationDate}>
        <DateField source="creationDate" record={record} {...rest} />
      </Labeled>
    </div>
  ) : null
);

export default ConditionalRecurrentDonationFields;