import React         from 'react';
import {
  TextField,
  RichTextField,
  TextInput,
  Edit,
  SimpleForm,
  Create,
  SimpleShowLayout,
  Show,
  useNotify,
}                                  from 'react-admin';
import RichTextInput               from 'ra-input-rich-text';
import { uploadImageToCloudinary } from '../addUploadFeature';
import { makeStyles } from '@material-ui/core/styles';
let notify;

const PageTitle = ({ record }) => {
  return <span>Datos de la Página {record.title}</span>;
};

const validatePage = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = ['Debe proporcionar el título de la página'];
  }
  if (!values.code) {
    errors.code = ['Debe especificar el título de la página'];
  }
  if (!values.content) {
    errors.content = ['Debe proveer el contenido de la página'];
  }
  return errors
};

// Quill.js (rich text editor) configuration
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  [{ 'direction': 'rtl' }],                         // text direction
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'align': [] }],
  ['image', 'video'],
  ['clean'],                                         // remove formatting button
  ['fullscreen']
];

const configureQuill = quill => quill
  .getModule('toolbar')
  .addHandler('image', function () {

    // Step 3: Insert url file to editor
    const insertToEditor = (url) => {
      // push image url to rich editor.
      const range = this.quill.getSelection();
      this.quill.insertEmbed(range.index, 'image', url);
    };

    // Step 2: Upload file to cloudinary
    const saveToServer = (file) => {
      if (notify) notify('custom.loadingImage');
      uploadImageToCloudinary(file).then(url => insertToEditor(url));
    };

    // Step 1: Get selected file
    const selectLocalImage = () => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.click();
      // Listen upload local image and save to server
      input.onchange = () => {
        const file = input.files[0];

        // file type is only image.
        if (/^image\//.test(file.type)) {
          saveToServer({ rawFile: file });
        } else {
          console.warn('You could only upload images.');
        }
      };
    };

    selectLocalImage();
  });

export const PageEdit = props => {
  notify = useNotify();
  return (
    <Edit title={<PageTitle />} {...props} undoable={false}>
      <SimpleForm validate={validatePage} redirect="show">
        <TextInput required={true} source="title" />
        <TextInput source="subtitle" />
        <RichTextInput
          style={{ maxHeight: 400 }}
          source="content"
          toolbar={toolbarOptions}
          configureQuill={configureQuill}
        />
      </SimpleForm>
    </Edit>
  )
};

export const PageCreate = props => {
  notify = useNotify();
  return (
    <Create title="Nueva página" {...props} undoable={false}>
      <SimpleForm validate={validatePage} redirect="show">
        <TextInput required={true} source="title" />
        <TextInput source="subtitle" />
        <TextInput source="code" />
        <RichTextInput source="content" toolbar={toolbarOptions} configureQuill={configureQuill} />
      </SimpleForm>
    </Create>
  )
};

export const PageShow = props => (
  <Show title={<PageTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="title" />
      <TextField source="subtitle" />
      <RichTextField source="content" />
    </SimpleShowLayout>
  </Show>
);