import React, { useState, useEffect } from 'react';
import { addField }                   from 'react-admin';
import TextField                      from '@material-ui/core/TextField';
import Autocomplete                   from '@material-ui/lab/Autocomplete';
import CircularProgress               from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import { get }  from '../api/httpClient';

let timerId = null;

const SalesforceAccountInput = ({ input, meta: { touched, error } }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const searchAccounts = () => (
    get(`/admin/salesforce-accounts?name=${search}`)
      .then(r => r.json())
  );

  const triggerSearch = (ev) => {
    if (!ev) return;
    const { value } = ev.target;
    setSearch(value);
    if (!value) {
      setOptions([]);
      return;
    }
    setLoading(true);
    if (timerId) clearTimeout(timerId);
    timerId = setTimeout(async () => {
      const results = await searchAccounts();
      setLoading(false);
      setOptions(results);
    }, 850);
  };

  const updateActualInput = (ev, value) => {
    input.onChange((value && value.Id) || '');
  };

  useEffect(() => {
    // Initialize input with default value
    input.onChange(input.value);
  }, []);

  return (
    <>
      <Autocomplete
        style={{ width: '100%', maxWidth: 512, background: 'rgba(0, 0, 0, 0.04)' }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        defaultValue={input.value}
        getOptionSelected={(option, value) => option.Name === value.Name}
        getOptionLabel={option => option.Name || input.value}
        options={options}
        loading={loading}
        loadingText="Cargando..."
        noOptionsText={!search ? 'Escribe para inciar una busqueda...' : 'No se encontraron resultados'}
        onInputChange={triggerSearch}
        onChange={updateActualInput}
        renderInput={params => (
          <TextField
            {...params}
            error={!!touched && !!error}
            label="Cuenta de SalesForce *"
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {!!touched && !!error && (
        <FormHelperText error={true}>{error}</FormHelperText>
      )}
    </>
  );
};
export default addField(SalesforceAccountInput);
