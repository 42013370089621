import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DateField,
  Responsive,
  SimpleList,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  SelectArrayInput,
  Labeled,
  ImageInput,
  ImageField,
  Filter,
  Show,
  SimpleShowLayout,
  Create,
  BooleanInput,
  BooleanField,
  UrlField,
  ArrayInput,
  SimpleFormIterator,
  ArrayField, SingleFieldList
} from 'react-admin';
import LogoField                          from '../components/LogoField';
import CategoryField, { categoryChoices } from '../components/CategoryField';
import SalesforceAccountInput             from '../components/SalesforceAccountInput';

const InstitutionFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Buscar por nombre" source="name" alwaysOn />
  </Filter>
);

export const InstitutionList = props => (
  <List
    filters={<InstitutionFilter />}
    {...props}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={record => record.name}
          tertiaryText={record => new Date(record.creationDate).toLocaleDateString()}
        />
      }
      medium={
        <Datagrid rowClick="show">
          <TextField source="salesforceId" />
          <LogoField source="logo" title="logo" />
          <TextField source="name" />
          <DateField source="creationDate" />
          <LogoField source="banner" title="banner" />
          <EditButton />
        </Datagrid>
      }
    />
  </List>
);

const InstitutionTitle = ({ record }) => {
  return <span>ONG {record ? `"${record.name}"` : ''}</span>;
};

const validatePrograms = (values) => {
  if (!values || values.length < 1) {
    return 'Debe ingresar al menos un programa';
  }
  if (!values || values.length > 6) {
    return 'El máximo de programas permitidos es 6';
  }
}

const validateText = (values) => {
  if (!values) {
    return 'El campo es obligatorio';
  }
}

const validateImages = (min, max) => (values) => {
  if (!values || values.length < min) {
    return 'El mínimo de imágenes permitidas es ' + min;
  }
  if (!values || values.length > max) {
    return 'El máximo de imágenes permitidas es ' + max;
  }
}

const validateInstitution = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = ['El nombre de la organización es obligatorio'];
  }
  if (!values.summary) {
    errors.summary = ['El resumen de la organización es obligatorio'];
  }
  if (!values.logo) {
    errors.logo = ['El logo de la organización es obligatorio'];
  }
  if (!values.salesforceId) {
    errors.salesforceId = ['El ID de SalesForce de la organización es obligatorio'];
  }
  return errors
};

export const InstitutionEdit = props => (
  <Edit title={<InstitutionTitle />} {...props} undoable={false}>
    <SimpleForm validate={validateInstitution} >
      <TextInput disabled source="id" />
      <TextInput required source="name" />
      <TextInput fullWidth={true} multiline source="summary" required />
      <SelectArrayInput fullWidth={true} label="Categoría" source="category" choices={categoryChoices} />
      <ImageInput source="logo" accept="image/*">
        <ImageField source="src" title="logo" />
      </ImageInput>
      <ImageInput source="banner" accept="image/*">
        <ImageField source="src" title="banner" />
      </ImageInput>
      <TextInput source="facebook" />
      <TextInput source="instagram" />
      <TextInput source="twitter" />
      <TextInput source="linkedIn" />
      <TextInput source="google" />
      <TextInput source="youtube" />
      <TextInput source="websiteUrl" />
      <BooleanInput source="deleted" />
      <SalesforceAccountInput source="salesforceId" />
      <DateInput disabled source="creationDate" />

      <TextInput required source="ngoHeader" label="NGO Header Title" validate={validateText} />
      <ImageInput source="ngoVideo" label="NGO Header Video"  accept="video/*">
        <ImageField source="src" title="ngoVideo" />
      </ImageInput>
      <ImageInput required source="ngoImages" label="NGO Header Images" accept="image/*" multiple={true}  validate={validateImages(1, 3)}>
        <ImageField source="src" title="ngoImages" />
      </ImageInput>
      <ArrayInput source="programs" validate={validatePrograms}>
        <SimpleFormIterator>
          <TextInput required source="programTitle" validate={validateText} />
          <ImageInput source="programImages" accept="image/*" multiple={true}  validate={validateImages(1, 6)}>
            <ImageField source="src" title="programImages" />
          </ImageInput>
          <TextInput required source="programDescription" validate={validateText} />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export const InstitutionCreate = props => (
  <Create title="Nueva ONG" {...props} undoable={false}>
    <SimpleForm redirect="show" validate={validateInstitution}>
      <TextInput required source="name" />
      <TextInput multiline source="summary" required />
      <SelectArrayInput label="Categoría" source="category" choices={categoryChoices} />
      <ImageInput required source="logo" accept="image/*">
        <ImageField source="src" title="logo" />
      </ImageInput>
      <ImageInput source="banner" accept="image/*">
        <ImageField source="src" title="banner" />
      </ImageInput>
      <TextInput source="facebook" />
      <TextInput source="instagram" />
      <TextInput source="twitter" />
      <TextInput source="linkedIn" />
      <TextInput source="google" />
      <TextInput source="youtube" />
      <TextInput source="websiteUrl" />
      <BooleanInput source="deleted" />
      <SalesforceAccountInput source="salesforceId" />
      <TextInput required source="ngoHeader" label="NGO Header Title" validate={validateText} />
      <ImageInput source="ngoVideo" label="NGO Header Video"  accept="video/*">
        <ImageField source="src" title="banner" />
      </ImageInput>
      <ImageInput required source="ngoImages" label="NGO Header Images" accept="image/*" multiple={true}  validate={validateImages(1, 3)}>
        <ImageField source="src" title="banner" />
      </ImageInput>
      <ArrayInput source="programs" validate={validatePrograms}>
        <SimpleFormIterator>
          <TextInput required source="programTitle" validate={validateText} />
          <ImageInput source="programImages" accept="image/*" multiple={true}  validate={validateImages(1, 6)}>
            <ImageField source="src" title="banner" />
          </ImageInput>
          <TextInput required source="programDescription" validate={validateText} />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);


export const InstitutionShow = props => (
  <Show title={<InstitutionTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="summary" />
      <Labeled label="Categoría">
        <CategoryField source="category" />
      </Labeled>
      <ImageField source="logo" title="logo" />
      <ImageField source="banner" title="banner" />
      <UrlField source="facebook" />
      <UrlField source="instagram" />
      <UrlField source="twitter" />
      <UrlField source="linkedIn" />
      <UrlField source="google" />
      <UrlField source="youtube" />
      <UrlField source="websiteUrl" />
      <BooleanField source="deleted" />
      <DateField source="creationDate" />
      <TextField source="salesforceId" />
      <TextField source="ngoHeader" />
      <ImageField source="ngoVideo" title="ngoVideo" />
      <ArrayField source="ngoImages">
        <SingleFieldList>
          <ImageField source="src" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="programs">
        <Datagrid>
          <TextField source="programTitle" />
          <ArrayField source="programImages">
            <SingleFieldList>
              <ImageField source="src" />
            </SingleFieldList>
          </ArrayField>
          <TextField source="programDescription" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
